<template>
    <div class="Hwithdrawal">
        <div class="my-top">
            <h1 @click="goback">x</h1>
            <p>提现明细</p>
            <h1>···</h1>
        </div>
        <div class="audit-wrap">
            <!-- 审核通过 -->
             <div class="audit-wrap-top" v-if="tixianlistdetail.order_status==1">
                  <p>￥{{tixianlistdetail.withdraw_amount}}</p>
                  <p><img src="../../../../assets/dd03.png" alt=""></p>
                  <p>提现审核通过</p>
             </div>
             <!-- 审核中 -->
              <div class="audit-wrap-top"  v-if="tixianlistdetail.order_status==2">
                  <p>￥{{tixianlistdetail.withdraw_amount}}</p>
                  <p><img src="../../../../assets/dd03.png" alt=""></p>
                  <p>提现申请已提交，等待人工审核</p>
             </div>
        </div>
        <!-- //审核通过 -->
        <ul class="audit-wrap-bottom" v-if="tixianlistdetail.order_status==1">
             <li>
                 <p>提现商城</p>
                 <p>{{tixianlistdetail.withdraw_mode}}</p>
             </li>
             <li>
                 <p>提现进度</p>
                 <p>等待平台客服审核</p>
             </li>
             <li>
                 <p>申请时间</p>
                 <p>{{tixianlistdetail.create_time}}</p>
             </li>
              <li>
                 <p>审核通过时间</p>
                 <p>{{tixianlistdetail.pay_time}}</p>
             </li>
             <li>
                 <p>实际到账金额</p>
                 <p>￥{{tixianlistdetail.withdraw_amount}}</p>
             </li>
              <li>
                 <p>手续费扣除</p>
                 <p>￥{{tixianlistdetail.service_fee}}</p>
             </li>
        </ul>
        <!-- 正在审核中 -->
         <ul class="audit-wrap-bottom" v-if="tixianlistdetail.order_status==2">
              <li>
                 <p>提现商城</p>
                 <p>{{tixianlistdetail.withdraw_mode}}</p>
             </li>
             <li>
                 <p>提现进度</p>
                 <p>等待平台客服审核</p>
             </li>
             <li>
                 <p>申请时间</p>
                 <p>{{tixianlistdetail.create_time}}</p>
             </li>
        </ul>
        <p class="beizhu">备注：各银行到账时间不同，请留意银行到账信息。</p>
    </div>
</template>
<style lang="scss">
    .Hwithdrawal{
        width: 100%;
        height: 100%;
        background: #f6f6f6;
        padding-bottom: 1.5rem;
        box-sizing: border-box;
        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }
        .audit-wrap{
           width: 100%;
           background: #fff;
           .audit-wrap-top{
               width: 3.63rem;
               margin: 0 auto;
               padding-top: 0.52rem;
               padding-bottom: 0.36rem;
               box-sizing: border-box;
               text-align: center;
               p:nth-child(1){
                   font-size: 0.37rem;
                   color: #ff5400;
               }
               p:nth-child(2) img{
                   width: 0.6rem;
                   height:0.62rem;
               }
               p:nth-child(3){
                   font-size: 0.25rem;
               }
           }
        }
        .audit-wrap-bottom{
           width: 100%;
           margin-top: 0.3rem;
           background: #fff;
            padding-left: 0.23rem;
            padding-right: 0.24rem;
            box-sizing: border-box;
           li{
               font-size: 0.26rem;
               display: flex;
               height: 1.12rem;
               padding-top: 0.67rem;
               box-sizing: border-box;
               justify-content: space-between;
               border-bottom: 1px solid #f0f0f0;
               p:nth-child(1){
                  color: #000022;
               }
               p:nth-child(2){
                  color: #7c7c7c;
               }
           }
       }
       .beizhu{
           font-size: 0.23rem;
           color: #7c7c7c;
           margin-top: 0.6rem;
           margin-left: 0.6rem;
       }
    }
</style>
<script>
import {TixianListDetail} from "../../../../http/api"
export default {
    data(){
        return{
            order_id:this.$route.query.id,
            tixianlistdetail:{}
        }
    },
    mounted(){
      this.TixianListDetail()
    },
    methods:{
        goback(){
            this.$router.go(-1)
        },
        //提现详情
        async TixianListDetail(){
           const res=await TixianListDetail({
               shop_id:this.$store.state.shop_info.shop_id,
               order_id:this.order_id
           })
           console.log(res)
           this.tixianlistdetail=res
        }
    }
}
</script>
